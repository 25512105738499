// import React, { useEffect, useState } from "react";
// import { AiOutlineRollback } from "react-icons/ai";
// import { BsArrowBarRight } from "react-icons/bs";
// import { BsArrowBarDown } from "react-icons/bs";
// import { Link } from "react-router-dom";
// import Data from "./Data";
// import firebase from "../../firebaseconfig";
// // import firebase from 'firebase/compat/app';
// import data2 from "./data2";
// import { FaBitcoin } from "react-icons/fa";

// const Finance = () => {
//   const [departmentData, setDepartmentData] = useState({});
//   const [records, setRecords] = useState([]);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [searchResults, setSearchResults] = useState([]);
//   const [balance, setBalance] =  useState()

//   const handleSearch = () => {
//     const filteredRecords = records.filter((record) => {
//       return (
//         record.price.includes(searchTerm) ||
//         record.date.includes(searchTerm) ||
//         record.from.includes(searchTerm) ||
//         record.type.includes(searchTerm)
//       );
//     });
//     setSearchResults(filteredRecords);
//   };

//   const handleClearSearch = () => {
//     setSearchTerm("");
//     setSearchResults([]);
//   };

//   useEffect(() => {
//     const db = firebase.firestore();
//     const transactionRef = db.collection("Transactions").orderBy('createdAt', 'desc')
//     transactionRef
//       .get()
//       .then((querySnapshot) => {
//         const data = [];
//         querySnapshot.forEach((doc) => {
//           const transactionData = doc.data();
//           data.push(transactionData);
//           console.log("Fetched data: ", transactionData);
//         });
//         setRecords(data);
//       })
//       .catch((error) => {
//         console.error("Error fetching data: ", error);
//       });
//   }, []);

//   useEffect(() => {
//     const db = firebase.firestore();
//     const transactionRef = db.collection("BCS");

//     transactionRef
//       .get()
//       .then((querySnapshot) => {
//         const data = [];
//         let totalBalance = 0; // Initialize total balance

//         querySnapshot.forEach((doc) => {
//           const transactionData = doc.data();
//           data.push(transactionData);

//           // Check if "balance" exists and add it to the total
//           if (transactionData.balance) {
//             totalBalance += transactionData.balance;
//           }

//           // console.log("Fetched data: ", transactionData.balance);
//         });

//       setBalance( totalBalance); // Log the total balance

//       })
//       .catch((error) => {
//         console.error("Error fetching data: ", error);
//       });
//   }, []);

//   const formatTimestamp = (timestamp) => {
//     if (timestamp) {
//       const date = timestamp.toDate(); // Convert to JavaScript Date
//       return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
//     } else {
//       return "Invalid Timestamp";
//     }
//   };

//   return (
//     <div className="ml-40">
//        <h1 className="text-gray-500 font-bold text-lg text-center m-5">
//         GENERAL
//       </h1>
//       <div className="lg:flex grid justify-center">
//         <div className="shadow-lg p-5 h-44 w-96 shadow-blue-200 bg-[#3ec9d6] rounded-md text-center">
//           <FaBitcoin size={50} color="#3ec9d6" className="self-center" />
//           <h2 className="font-extrabold text-5xl -mt-5 text-white m-4">
//           Balance
//           </h2>
//           <h2 className="font-extrabold text-2xl text-white m-4">
//             {/* {balance} */}
//             ₦{balance ? balance.toLocaleString("en-US") : '0'}
//           </h2>
//         </div>

// {
//   firebase?.auth()?.currentUser?.email === 'bcsict@ooobcs.org' &&
//         <Link to={'/Add'} className="shadow-lg p-5 h-44 w-96 cursor-pointer ml-10 shadow-blue-200 bg-[#3ec9d6] rounded-md text-center">
//           <FaBitcoin size={50} color="#3ec9d6" className="self-center" />
//           <h2 className="font-extrabold text-5xl -mt-5 text-white m-4">
//           Add
//           </h2>
//         </Link>
// }
//       </div>

//       <div className="mt-20">
//         <table className="w-full text-sm text-left dark:text-gray-400">
//           <thead className="uppercase bg-gray-50 dark:bg-gray-700">
//             <tr>
//               <th scope="col" className="px-6 py-3">
//                 Type
//               </th>
//               <th scope="col" className="px-6 py-3">
//                 AMOUNT
//               </th>
//               <th scope="col" className="px-6 py-3">
//                 PURPOSE
//               </th>
//               <th scope="col" className="px-6 py-3">
//                 DEPARTMENT
//               </th>
//               <th scope="col" className="px-6 py-3">
//                 PAID BY
//               </th>

//               <th scope="col" className="px-6 py-3">
//                 TRACKING ID
//               </th>
//               <th scope="col" className="px-6 py-3">
//                 DATE
//               </th>
//             </tr>
//           </thead>
//           <tbody>
//             {searchResults.length > 0
//               ? searchResults.map((record, index) => (
//                   <tr key={index}>
//                     <td
//                       className={`px-4 py-2 font-bold text-lg ${
//                         record.type === "Withdrawal"
//                           ? "text-red-500"
//                           : "text-green-500"
//                       }`}
//                     >
//                       {record.type}
//                     </td>
//                     <td className="px-4 py-2 font-bold text-lg">
//                       {record.from}
//                     </td>
//                     <td className="px-4 py-2 font-bold text-lg">
//                     ₦{record.PayeeAmount ? record.PayeeAmount.toLocaleString("en-US") : '0'}
//                     </td>
//                     <td className="px-4 py-2 font-bold text-lg">
//                       {record.date}
//                     </td>
//                   </tr>
//                 ))
//               : records.map((record, index) => (
//                   <tr key={index}>
//                     <td
//                       className={`px-4 py-2 font-bold text-lg ${
//                         record.type === "Withdrawal"
//                           ? "text-red-500"
//                           : "text-green-500"
//                       }`}
//                     >
//                       {record.type}
//                     </td>
//                     <td className="px-4 py-2 font-bold text-lg">
//                       ₦{record.PayeeAmount ? record.PayeeAmount.toLocaleString("en-US") : '0'}
//                     </td>
//                     <td className="px-4 py-2 font-bold text-lg">
//                       {record.PaymentDescription}
//                     </td>
//                     <td className="px-4 py-2 font-bold text-lg">

//                       {record.PayeePurpose}
//                     </td>
//                     <td className="px-4 py-2 font-bold text-lg">
//                       {record.payeeName}
//                     </td>
//                     <td className="px-4 py-2 font-bold text-lg">
//                       {record.transactionId}
//                     </td>
//                     <td className="px-4 py-2 font-bold text-lg">
//                     {record.createdAt ? formatTimestamp(record.createdAt) : 'No date available'}
//                     </td>
//                   </tr>
//                 ))}
//           </tbody>
//         </table>
//       </div>
//     </div>
//   );
// };

// export default Finance;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, Space, Input, DatePicker, Button } from "antd";
import firebase from "../../firebaseconfig";
import { FaBitcoin, FaTrash } from "react-icons/fa";
import { auth } from "../../firebase";
import { CgRemove } from "react-icons/cg";

const Finance = () => {
  const db = firebase.firestore();
  const [records, setRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [balance, setBalance] = useState();
  const [loading, setLoading] = useState(false);
  const { Search } = Input;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [Mandate, setMandate] = useState(0);
  const [MandateB, setMandateB] = useState(0);

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text, record) => (
        <span
        className={`font-bold text-lg ${
          record.type === "Withdrawal"
            ? "text-red-500"
            : record.type === "Transfer"
            ? "text-red-300"
            : "text-green-500"
        }`}
          // className={`font-bold text-lg ${
          //   record.type === "Withdrawal" ? "text-red-500" : "text-green-500"
          // }`}
        >
          {record.type}
        </span>
      ),
    },
    {
      title: "Amount",
      dataIndex: "PayeeAmount",
      key: "PayeeAmount",
      render: (text, record) => (
        <span
        className={`font-bold text-lg ${
          record.type === "Withdrawal"
            ? "text-red-500"
            : record.type === "Transfer"
            ? "text-red-300"
            : "text-green-500"
        }`}
      >
        ₦ {record.PayeeAmount.toLocaleString("en-US")}
        {/* ₦ {text?.toLocaleString("en-US") || "0"} */}
      </span>
      ),
    },
    {
      title: "Purpose",
      dataIndex: "PaymentDescription",
      key: "PaymentDescription",
      render: (text, record) => (
        <span className="font-bold text-lg">{record.PaymentDescription}</span>
      ),
    },
    {
      title: "Department",
      dataIndex: "PayeePurpose",
      key: "PayeePurpose",
      render: (text, record) => (
        <Link to={`/department/${record.bcsid}`}><span className="font-bold text-lg">{record.PayeePurpose}</span></Link>
      ),
    },
    {
      title: "Paid By",
      dataIndex: "payeeName",
      key: "payeeName",
      render: (text, record) => (
        <span className="font-bold text-lg">{record.payeeName}</span>
      ),
    },
    {
      title: "Action",
      dataIndex: "toggle",
      key: "toggle",
      render: (text, record) => (
        <Space size="middle">
          {firebase.auth().currentUser.email === "bcsict@ooobcs.org" && (
            <>
                <Button
                  type="primary"
                  className="bg-[#ff2250]"
                  onClick={async () => {
                    const confirmed = window.confirm("Are you sure you want to delete this transaction?");
                  
                    if (confirmed) {
                      try {
                        // Delete the transaction document
                        await db.collection("Transactions").doc(record.id).delete();
                  
                        // Get the BCS document reference
                        const bcsDocRef = db.collection("BCS").doc(record.bcsid);
                  
                        // Get the current balance and totalBalance (using a transaction)
                        const transaction = await db.runTransaction(async (transaction) => {
                          const bcsDoc = await transaction.get(bcsDocRef);
                          const currentBalance = bcsDoc.exists ? bcsDoc.data().balance : 0;
                          const currentTotalBalance = bcsDoc.exists ? bcsDoc.data().totalBalance : 0;
                          return { currentBalance, currentTotalBalance };
                        });
                  
                        // Calculate new balance and totalBalance
                        const newBalance = transaction.currentBalance - record.PayeeAmount;
                        const newTotalBalance = transaction.currentTotalBalance - record.PayeeAmount;
                  
                        // Update the BCS document with new balance and totalBalance (using merge)
                        await bcsDocRef.set({ balance: newBalance, totalBalance: newTotalBalance }, { merge: true });
                  
                        console.log("Deleted Successfully");
                        console.log("Oku", record.id);
                        alert("Deleted Successfully");
                      } catch (error) {
                        console.error("Error saving payment data: ", error);
                      }
                    }
                  }}
                  

                >
                  <FaTrash size={20} />
                </Button>
            </>
          )}
        </Space>
      ),
    },
    {
      title: "Tracking ID",
      dataIndex: "transactionId",
      key: "transactionId",
      render: (text, record) => (
        <span className="font-bold text-lg">{record.transactionId}</span>
      ),
    },
    {
      title: "Current Balance",
      dataIndex: "currentBalance",
      key: "currentBalance",
      render: (text, record) => (
        <span
        className={`font-bold text-lg ${
            "text-green-500"
        }`}
      >
        ₦ {record?.currentBalance?.toLocaleString("en-US")}
        {/* ₦ {text?.toLocaleString("en-US") || "0"} */}
      </span>
      ),
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => (
        <span className="font-bold text-lg">
          {record.createdAt
            ? formatTimestamp(record.createdAt)
            : "No date available"}
        </span>
      ),
    },
  ];

  // const fetchRecords = async () => {
  //   try {
  //     const snapshot = await firebase.firestore().collection("Transactions").orderBy("createdAt", "desc").get();
  //     const data = snapshot.docs.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data(),
  //     }));
  //     setRecords(data);
  //   } catch (error) {
  //     console.error("Error fetching data: ", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchRecords();
  // }, []);

  useEffect(() => {
    const db = firebase.firestore();
    setLoading(true);
    const transactionRef = db
      .collection("Transactions")
      .orderBy("createdAt", "desc");
    transactionRef
    .get()
    .then((querySnapshot) => {
      const data = [];
      querySnapshot.forEach((doc) => {
        const transactionData = doc.data();
        transactionData.id = doc.id; // Add document ID to each object
        data.push(transactionData);
      });
      setRecords(data);
      setLoading(false);
    })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });
  }, []);


  const CallMandate  = async() =>{
    const db = firebase.firestore();
    const transactionRefMandate = db.collection("Mandate");
    const transactionRef = db.collection("BCS");
    let Minus = 0
    try{

      transactionRefMandate
      .get()
      .then((querySnapshot) => {
        const data = [];
        let totalBalance = 0; // Initialize total balance

        querySnapshot.forEach((doc) => {
          const transactionData = doc.data();
          data.push(transactionData);

          // Check if "balance" exists and add it to the total
          if (transactionData.amount) {
            totalBalance += transactionData.amount;
            Minus += transactionData.amount;
          }

          // console.log("Fetched data: ", transactionData.balance);
        });

        // setMandateB(totalBalance); // Log the total balance
        // alert(MandateB)
        // setBalance(totalBalance - 60000000); // Log the total balance
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
      });

      await transactionRef
           .get()
           .then((querySnapshot) => {
             const data = [];
             let totalBalance = 0; // Initialize total balance
     
             querySnapshot.forEach((doc) => {
               const transactionData = doc.data();
               data.push(transactionData);
     
               // Check if "balance" exists and add it to the total
               if (transactionData.balance) {
                 totalBalance += transactionData.balance;
               }
     
               // console.log("Fetched data: ", transactionData.balance);
             });
     
         setBalance(totalBalance - Minus); // Log the total balance
             // setBalance(totalBalance - 60000000); // Log the total balance
           })
           .catch((error) => {
             console.error("Error fetching data: ", error);
           });
    } catch {
      console.log("Problem With Fetching Data")
    }

    }


  useEffect(() => {
    CallMandate()
  }, []);

  const formatTimestamp = (timestamp) => {
    if (timestamp) {
      const date = timestamp.toDate(); // Convert to JavaScript Date
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    } else {
      return "Invalid Timestamp";
    }
  };

  const handleSearch = (value) => {
    const lowerCaseSearchTerm = value.toLowerCase();
    const filteredRecords = records.filter((record) => {
      return (
        (record.PayeeAmount &&
          record.PayeeAmount.toString().includes(lowerCaseSearchTerm)) ||
        (record.transactionId &&
          record.transactionId.includes(lowerCaseSearchTerm)) ||
        (record.payeeName &&
          record.payeeName.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (record.PayeePurpose &&
          record.PayeePurpose.toLowerCase().includes(lowerCaseSearchTerm)) ||
        (record.PaymentDescription &&
          record.PaymentDescription.toLowerCase().includes(
            lowerCaseSearchTerm
          )) ||
        (record.type && record.type.toLowerCase().includes(lowerCaseSearchTerm))
      );
    });
    setSearchResults(filteredRecords);
  };
  const filterRecordsByDateRange = () => {
    if (startDate && endDate) {
      const filteredRecords = records.filter((record) => {
        const recordDate = record.createdAt.toDate(); // Update this line to access the correct field name
        return recordDate >= startDate && recordDate <= endDate;
      });
      setSearchResults(filteredRecords);
    } else {
      // If no date range is selected, reset searchResults to show all records
      setSearchResults(records);
    }
  };
  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      setStartDate(dates[0]);
      setEndDate(dates[1]);
    }
  };

  const data = {
    amount: parseFloat(Mandate),
    email: auth?.currentUser?.email,
    createdAt: new Date()
  };
  const firestore = firebase.firestore();

  const HandleMandate = async () => {
    try {
      await firestore.collection("Mandate").add(data);
      setMandate(0)
      // console.log("Payment data saved successfully");
      alert('Mandate Successful')
    } catch (error) {
      console.error("Error saving payment data: ", error);
    }
  }
  return (
    <div className="md:ml-20">
      {/* <h1 className="text-gray-500 font-bold text-lg text-center m-5">GENERAL</h1> */}
      <div className="lg:flex grid justify-center">
      <p className="font-bold text-sm text-gray-800 p-5 ml-10">
            Total Transactions {records?.length?.toLocaleString("en-US")}
          </p>
        <div className="shadow-lg p-5 h-44 w-96 shadow-blue-200 bg-[#3ec9d6] rounded-md text-center ml-10 mb-7">
          <FaBitcoin size={50} color="#3ec9d6" className="self-center" />
          <h2 className="font-extrabold text-5xl -mt-5 text-white m-4">
            Balance
          </h2>
          <h2 className="font-extrabold text-2xl text-white m-4">
            ₦{balance ? balance?.toLocaleString("en-US") : "0"}
          </h2>
        </div>

        {firebase?.auth()?.currentUser?.email === "bcsict@ooobcs.org" && (
          <Link
            to={"/Add"}
            className="shadow-lg p-5 h-44 w-96 cursor-pointer ml-10 shadow-blue-200 bg-[#3ec9d6] rounded-md text-center mr-10"
          >
            <FaBitcoin size={50} color="#3ec9d6" className="self-center" />
            <h2 className="font-extrabold text-5xl -mt-5 text-white m-4">
              Add
            </h2>
          </Link>
        )}

        {[ "bcsict@ooobcs.org","hr@ooobcs.org", "bcstreasury@ooobcs.org"].includes(auth?.currentUser?.email) && (
          <Link
            // to={"/Add"}
            className="shadow-lg p-5 h-44 w-96 cursor-pointer ml-0 shadow-blue-200 bg-[#3ec9d6] rounded-md text-center mr-10"
          >
            <FaBitcoin size={50} color="#3ec9d6" className="self-center" />
            <h2 className="font-extrabold text-4xl -mt-14 text-white m-4">
              Mandate
            </h2>
            <input 
            type="number"
            onChange={(e) => setMandate(e.target.value)}
            placeholder="Enter Amount"
            className="mb-5 text-sm p-2 rounded-md"
            />
            <Link
            onClick={HandleMandate}
          className="shadow-lg p-3 text-xs text-white cursor-pointer ml-0 shadow-blue-200 bg-[#ff2250] rounded-md text-center"
            >
            Confirm
            </Link>
          </Link>
        )}
      </div>
      <div className="flex row">
        <Search
          placeholder="Search by Name, Amount, Date, or Purpose"
          onSearch={handleSearch}
          onChange={(e) => setSearchTerm(e.target.value)}
          value={searchTerm}
          style={{ width: "100%", maxWidth: "400px", marginBottom: "1rem" }}
          className="bg-blue-400 rounded-xl"
          enterButton
        />

        <div className="flex row ml-16">
          <DatePicker.RangePicker
            onChange={handleDateRangeChange}
            className="ml-3 h-8"
            // style={{ marginRight: "10px" }}
          />
          {/* <div className="flex row bg-emerald-500 ml-4 rounded-xl "> */}
            <Button type="primary" onClick={filterRecordsByDateRange} className="bg-blue-600 ml-7">
              Filter
            </Button>
          {/* </div> */}
        </div>
      </div>

      <div className="mt-20 relative overflow-x-auto shadow-md sm:rounded-lg">
        <Table
          columns={columns}
          loading={loading}
          dataSource={
            searchResults.length > 0 ? searchResults : records // Use searchResults if available, otherwise fallback to records
          }
          pagination={{
            pageSize: 10,
          }}
          className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
        />
      </div>
    </div>
  );
};

export default Finance;
