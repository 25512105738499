import "./assets/libs/boxicons-2.1.1/css/boxicons.min.css";
import "./scss/App.scss";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Blank from "./components/Table/Blank";
import Dashboard from "./pages/Dashboard";
import MainLayout from "./layout/MainLayout";
import Products from "./pages/Products";
import CreateProducts from "./pages/CreateProducts";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { onAuthStateChanged , signOut} from "firebase/auth";
import { useEffect, useState } from "react";
import { auth } from "./firebase";
import Login from "./pages/Login";
import Customers from "./pages/Customers";
import AllVideos from "./pages/AllVideos";
import Orders from "./pages/Orders";
import NewVideo from "./pages/NewVideo";
import OrderAttempts from "./pages/OrderAttempts";
import SuccessfulDelivery from "./pages/SuccessfulDelivery";
import Department from "./pages/Departments";
import CreateDepart from "./pages/CreateDepatr";
import PayRoll from "./components/Table/PayRoll";
import AddToPayroll from "./pages/Payroll";
import DepartmentScreen from "./pages/DepartmentScreen";
import { db } from "./firebase";
import { collection, getDocs } from "firebase/firestore";
import Signup from "./pages/CreateUser";
import { UserProvider } from "./components/user-info/UserContext";
import Finance from "./components/finance/Finance";
import DirectTithe from "./components/finance/Direct";
import Baya from './components/finance/Baya';
import Events from './components/finance/Events'
import Fasting from './components/finance/Fasting'
import Ict from './components/finance/Ict'
import Triple_Star from './components/finance/Triple_Star'
import Stv from './components/finance/Stv'
import Audio from './components/finance/Audio'
import Feast from './components/finance/Feast'
import Personal from './components/finance/Personal'
import Supreme from './components/finance/Supreme_temple'
import TaxBalance from './components/finance/TaxBalance'
import Query from "./pages/Query";
import Departmentbalance from "./pages/DepartmentBalance";
import DepartmentBalance from "./pages/DepartmentBalance";
import Fellowship from "./components/Table/Blank";
import EditDepartment from "./pages/editDepartment";
import DepartFinance from "./components/finance/DepartmentBalance";
import FinanceA from "./components/finance/FinanceA";
import AddFinance from "./pages/AddFinance";
import RetirementForm from "./components/Retirements/Retirement";
import ViewRetirements from "./components/Retirements/ViewRetirements";
import RequisitionForm from "./components/Requsition/Requisition";
import ViewRequests from "./components/Requsition/ViewRequsition";
import AccptedRequzitions from "./components/Requsition/AcceptedRequsition";
import AcceptedRetirements from "./components/Retirements/AcceptedRetirements";
import Treasury from "./pages/AllWithdrawal";
import StateRetirement from "./components/Retirements/StateRetirements";
import Mandate from "./components/finance/Mandate";






function App() {
  const [authenticated, setAuthenticated] = useState();
  const [departments, setDepartments] = useState([]);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const fetchDepartments = async () => {
      const querySnapshot = await getDocs(collection(db, "Departments"),);
      const departmentsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        staffCount: 0, // Initialize staff count to 0
      }));

      // Fetch staff count for each department
      const staffQuerySnapshot = await getDocs(collection(db, "Staff"));
      const staffData = staffQuerySnapshot.docs.map((doc) => doc.data());

      // Count staff members for each department
      for (const department of departmentsData) {
        const staffInDepartment = staffData.filter(
          (staff) => staff.department === department.department_name
        );
        department.staffCount = staffInDepartment.length;
      }

      setDepartments(departmentsData);
    };

    fetchDepartments();
  }, []);

  useEffect(() => {
    const onBeforeUnload = (e) => {
      // Trigger sign out when the user closes the site.
      if (authenticated) {
        signOut(auth)
          .then(() => {
            setAuthenticated(false);
          })
          .catch((error) => {
            // Handle sign-out errors if needed.
            console.error("Error signing out:", error);
          });
      }
    };

    window.addEventListener("beforeunload", onBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [authenticated]);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
      }
    });
  }, [])

  return (
    <UserProvider>
      <BrowserRouter>
        <ToastContainer />
        <Routes>
          <Route path="/"
          element={authenticated ? <MainLayout /> : <Login />}> 
            
        {/* {authenticated ? (
              <Route index element={<Dashboard />} />
            ) : (
              <Route index element={<Login />} />
            )} */}
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="orders" element={<Orders />} />
            <Route path="Signup" element={<Signup />} />
            {[ "bcsict@ooobcs.org","bcstreasury@ooobcs.org","hr@ooobcs.org",].includes(auth?.currentUser?.email) && <Route path="finance" element={<Finance />} />}
            {[ "bcsict@ooobcs.org","bcstreasury@ooobcs.org","hr@ooobcs.org",].includes(auth?.currentUser?.email) && <Route path="mandate" element={<Mandate />} />}
            {auth?.currentUser?.email === 'bcsict@ooobcs.org' && <Route path="financeA" element={<FinanceA />} />}
            <Route path="sucDev" element={<SuccessfulDelivery />} />
            <Route path="orderAttempts" element={<OrderAttempts />} />
            <Route path="products" element={<Products />} />
            <Route path="hero" element={<AllVideos />} />
            <Route path="create_product" element={<CreateProducts />} />
            <Route path="customers" element={<Customers />} />
            <Route path="departbalance" element={<DepartFinance />} />
            <Route path="departs" element={<Department />} />
            <Route path="bcs" element={<Fellowship />} />
            <Route path="edit/:id" element={<EditDepartment />} />
            <Route path="departmentbalance/:id" element={<DepartmentBalance />} />
            <Route
              path="/department/:department_id"
              element={<DepartmentScreen departmentsData={departments} />}
            />
            <Route path="create-depart" element={<CreateDepart />} />
            <Route path="addToPayroll" element={<PayRoll />} />
            <Route path="addPayroll" element={<AddToPayroll />} />
            <Route path="stats" element={<Blank />} />
           {auth?.currentUser?.email === 'bcsict@ooobcs.org' && <Route path="Add" element={<AddFinance />} />}
            <Route path="direct_tithe" element={<DirectTithe />} />
            <Route path="personal" element={<Personal />} />
            <Route path="supreme" element={<Supreme />} />
            <Route path="tax" element={<TaxBalance />} />
            <Route path="baya" element={<Baya />} />
            <Route path="events" element={<Events />} />
            <Route path="fastingFeast" element={<Fasting />} />
            <Route path="ict" element={<Ict />} />
            <Route path="triple_star" element={<Triple_Star />} />
            <Route path="stv" element={<Stv />} />
            <Route path="audio" element={<Audio />} />
            <Route path="feast" element={<Feast />} />
            {[ "bcsict@ooobcs.org","hr@ooobcs.org", "audit@ooobcs.org"].includes(auth?.currentUser?.email) &&  <Route path="viewretirements" element={<ViewRetirements />} />}
            <Route path="retirements" element={<RetirementForm />} />
            <Route path="requisition" element={<RequisitionForm />} />
            <Route path="accepted" element={<AccptedRequzitions />} />
            <Route path="acceptedments" element={<AcceptedRetirements />} />
            <Route path="stateretirement" element={<StateRetirement/>} />
            <Route path="Treasury" element={<Treasury />} />
            {[ "bcsict@ooobcs.org", "hr@ooobcs.org",].includes(auth?.currentUser?.email) && <Route path="viewrequisition" element={<ViewRequests />} />}
          </Route>
        </Routes>
      </BrowserRouter>
    </UserProvider>
  );
}

export default App;
