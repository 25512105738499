import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { db } from "../../firebase";
import { addDoc, collection } from "firebase/firestore";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { useUser } from "../user-info/UserContext";
import { Button, Form, Input } from "antd";

const RetirementForm = () => {
    const { userDepartment } = useUser();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [submitted, setSubmitted] = useState(false);

    const onFinish = (values) => {
        console.log("Form values:", values);
        setSubmitted(true);
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const [message, setMessage] = useState("");
    const [formData, setFormData] = useState({
        date: "",
        fellowship: "",
        topic: "",
        comment: "",
        Head: "",
        financeOfficer: "",
        treasurer: "",
        category: userDepartment.role,
        stateid: userDepartment.department,
        recomend: "",
        cleared: "",
        dateofrequest: "",
        dateofretire: "",
        requestPurpose: "",
        previousQuery: "",
        attachments: [],
        attachmentInfo: [],
    });

    const handleChange = (field, value) => {
        setFormData({
            ...formData,
            [field]: value,
        });
    };

    const handleFileChange = (e) => {
        const files = e.target.files;
        const fileInformationArray = [];

        for (let i = 0; i < files.length; i++) {
            const fileInformation = {
                name: files[i].name,
                type: files[i].type,
                size: files[i].size,
            };
            fileInformationArray.push(fileInformation);
        }

        setFormData({
            ...formData,
            attachments: files,
            attachmentInfo: fileInformationArray,
        });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const { attachments, ...formDataWithoutAttachment } = formData;

            const storage = getStorage();
            const attachmentsURLs = [];

            for (let i = 0; i < attachments.length; i++) {
                const attachment = attachments[i];
                const storageRef = ref(storage, `attachments/${attachment.name}`);
                await uploadBytes(storageRef, attachment);
                const downloadURL = await getDownloadURL(storageRef);
                attachmentsURLs.push({
                    name: attachment.name,
                    type: attachment.type,
                    size: attachment.size,
                    url: downloadURL,
                });
            }

            const serviceId = "service_owpr54g";
            const templateId = "template_9pdslx3";
            const publicKey = "FT1CqxJACcMzE9um8";

            const templateParams = {
                name: userDepartment.name,
                email: userDepartment.email,
                to_name: "Web Wizard",
                message: message,
            };

            emailjs
                .send(serviceId, templateId, templateParams, publicKey)
                .then((response) => {
                    console.log("Email sent successfully!", response);
                })
                .catch((error) => {
                    console.error("Error sending email:", error);
                });

            const docRef = await addDoc(collection(db, "retirements"), {
                ...formDataWithoutAttachment,
                attachments: attachmentsURLs,
                approved: false,
            });

            toast.success(
                "Your Retirement request has been sent. The Audit unit will look into it and get back to you soon 🤝🤝🤝"
            );

            setFormData({
                date: "",
                fellowship: "",
                topic: "",
                comment: "",
                Head: "",
                financeOfficer: "",
                treasurer: "",
                category: userDepartment.role,
                stateid: userDepartment.department,
                recomend: "",
                cleared: "",
                dateofrequest: "",
                dateofretire: "",
                requestPurpose: "",
                previousQuery: "",
                attachments: [],
                attachmentInfo: [],
            });

            setLoading(false);
        } catch (error) {
            toast.error(`Error adding document: ${error}`);
            setLoading(false);
        }
    };

    return (
        <div className="max-w-2xl mx-auto mt-8 p-4 bg-white rounded shadow-md">
            <h1 className="text-2xl font-bold mb-4 text-gray-800">
                Retirement of Funds
            </h1>
            <form onSubmit={handleFormSubmit}>
                <div className="flex mb-4">
                    <div className="w-1/2 mr-2">
                        <label htmlFor="date" className="text-sm text-gray-600">
                            Date
                        </label>
                        <input
                            type="text"
                            id="date"
                            placeholder="MM/DD/YYYY"
                            className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                            value={formData.date}
                            onChange={(e) => handleChange("date", e.target.value)}
                            required
                        />
                    </div>
                    <div className="w-1/2 ml-2">
                        <label htmlFor="fellowship" className="text-sm text-gray-600">
                            Organ
                        </label>
                        <input
                            type="text"
                            id="fellowship"
                            placeholder="Name of Fellowship / Department / State / Group"
                            className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                            value={formData.fellowship}
                            onChange={(e) => handleChange("fellowship", e.target.value)}
                            required
                        />
                    </div>
                </div>

                <div className="mb-4">
                    <label htmlFor="topic" className="text-sm text-gray-600">
                        Title
                    </label>
                    <input
                        placeholder="Retirement For........"
                        type="text"
                        id="topic"
                        className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                        value={formData.topic}
                        onChange={(e) => handleChange("topic", e.target.value)}
                        required
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="attachment" className="text-sm text-gray-600">
                        Attachment
                    </label>
                    <input
                        type="file"
                        id="attachment"
                        accept=".jpeg, .jpg, .pdf, .doc, .docx, .xls, .xlsx"
                        onChange={handleFileChange}
                        className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                        multiple
                    />
                </div>

                <div className="mb-4">
                    <label htmlFor="comment" className="text-sm text-gray-600">
                        Comment
                    </label>
                    <ReactQuill
                        id="comment"
                        value={formData.comment}
                        onChange={(value) => handleChange("comment", value)}
                        theme="snow"
                        className="border rounded focus:outline-none focus:ring focus:border-blue-300"
                    />
                </div>

                <h1 className="font-bold">Certificate of Honor</h1>
                <div className="flex mb-4">
                    <div className="w-1/2 mr-2">
                        <label htmlFor="dateofrequest" className="text-sm text-gray-600">
                            Date of Requisition
                        </label>
                        <input
                            type="text"
                            id="dateofrequest"
                            placeholder="MM/DD/YYYY"
                            className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                            value={formData.dateofrequest}
                            onChange={(e) => handleChange("dateofrequest", e.target.value)}
                            required
                        />
                    </div>
                    <div className="w-1/2 mr-2">
                        <label htmlFor="dateofretire" className="text-sm text-gray-600">
                            Date of Retirement
                        </label>
                        <input
                            type="text"
                            id="dateofretire"
                            placeholder="MM/DD/YYYY"
                            className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                            value={formData.dateofretire}
                            onChange={(e) => handleChange("dateofretire", e.target.value)}
                            required
                        />
                    </div>
                </div>

                <div className="flex mb-4">
                    <div className="w-1/2 mr-2">
                        <label htmlFor="requestPurpose" className="text-sm text-gray-600">
                            Requisition Purpose
                        </label>
                        <input
                            type="text"
                            id="requestPurpose"
                            placeholder="Requisition Purpose"
                            className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                            value={formData.requestPurpose}
                            onChange={(e) => handleChange("requestPurpose", e.target.value)}
                            required
                        />
                    </div>
                    <div className="w-1/2 ml-2">
                        <label htmlFor="previousQuery" className="text-sm text-gray-600">
                            Previous Query (optional)
                        </label>
                        <input
                            type="text"
                            id="previousQuery"
                            placeholder="Previous Query if any"
                            className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                            value={formData.previousQuery}
                            onChange={(e) => handleChange("previousQuery", e.target.value)}
                        />
                    </div>
                </div>
                <div className="flex mb-4">
                    <div className="w-1/2 mr-2">
                        <label htmlFor="cleared" className="text-sm text-gray-600">
                            Application Cleared by Audit Date
                        </label>
                        <input
                            type="text"
                            id="cleared"
                            placeholder="MM/DD/YYYY"
                            className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                            value={formData.cleared}
                            onChange={(e) => handleChange("cleared", e.target.value)}
                            required
                        />
                    </div>
                    <div className="w-1/2 ml-2">
                        <label htmlFor="recomend" className="text-sm text-gray-600">
                            Audit Recommendation
                        </label>
                        <input
                            type="text"
                            id="recomend"
                            placeholder="Audit Recommendation"
                            className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                            value={formData.recomend}
                            onChange={(e) => handleChange("recomend", e.target.value)}
                        />
                    </div>
                </div>

                <div className="mb-4">
                    <h2 className="text-lg font-bold mb-2 text-gray-800">Attested by</h2>
                    <div className="flex mb-4">
                        <div className="w-1/3 mr-2">
                            <label htmlFor="Head" className="text-sm text-gray-600">
                                Head / Number
                            </label>
                            <input
                                type="text"
                                id="Head"
                                className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                                value={formData.Head}
                                onChange={(e) => handleChange("Head", e.target.value)}
                                required
                            />
                        </div>
                        <div className="w-1/3 mx-2">
                            <label htmlFor="financeOfficer" className="text-sm text-gray-600">
                                Finance Officer / Number
                            </label>
                            <input
                                type="text"
                                id="financeOfficer"
                                className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                                value={formData.financeOfficer}
                                onChange={(e) => handleChange("financeOfficer", e.target.value)}
                                required
                            />
                        </div>
                        <div className="w-1/3 ml-2">
                            <label htmlFor="treasurer" className="text-sm text-gray-600">
                                Treasurer / Number
                            </label>
                            <input
                                type="text"
                                id="treasurer"
                                className="w-full p-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                                value={formData.treasurer}
                                onChange={(e) => handleChange("treasurer", e.target.value)}
                                required
                            />
                        </div>
                    </div>
                </div>

                <div className="mb-4">
                    <button
                        type="submit"
                        className="bg-blue-500 text-white p-2 rounded hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300 ml-72 mt-2"
                    >
                        {loading ? "Submitting..." : "Submit"}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default RetirementForm;
